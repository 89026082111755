<script lang="ts" setup>
import { Method } from '@inertiajs/core'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset'
    size?: 'lg',
    href?: string
    method?: Method
    as?: string
  }>(),
  {
    type: 'button',
  }
)

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'lg':
      return 'px-6 py-4 text-md tracking-wide'
    default:
      return 'px-4 py-2 text-xs tracking-wide'
  }
})
</script>
<template>
  <component
    :is="href && as !== 'a' ? Link : (as ?? 'button')"
    :as="href ? as : 'button'"
    :href="href"
    :method="href ? method : undefined"
    :type="! href && (as ?? 'button') === 'button' ? type : undefined"
    :class="sizeClasses">
    <slot />
  </component>
</template>
